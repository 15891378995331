// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-blog-autogenerating-screenshots-md": () => import("/opt/build/repo/src/pages/blog/autogenerating-screenshots.md" /* webpackChunkName: "component---src-pages-blog-autogenerating-screenshots-md" */),
  "component---src-pages-blog-boardgame-io-0-33-md": () => import("/opt/build/repo/src/pages/blog/boardgame.io-0.33.md" /* webpackChunkName: "component---src-pages-blog-boardgame-io-0-33-md" */),
  "component---src-pages-blog-frankenstein-md": () => import("/opt/build/repo/src/pages/blog/frankenstein.md" /* webpackChunkName: "component---src-pages-blog-frankenstein-md" */),
  "component---src-pages-blog-interviewing-md": () => import("/opt/build/repo/src/pages/blog/interviewing.md" /* webpackChunkName: "component---src-pages-blog-interviewing-md" */),
  "component---src-pages-blog-jest-function-coverage-md": () => import("/opt/build/repo/src/pages/blog/jest-function-coverage.md" /* webpackChunkName: "component---src-pages-blog-jest-function-coverage-md" */),
  "component---src-pages-blog-react-imperative-md": () => import("/opt/build/repo/src/pages/blog/react-imperative.md" /* webpackChunkName: "component---src-pages-blog-react-imperative-md" */),
  "component---src-pages-blog-repl-md": () => import("/opt/build/repo/src/pages/blog/repl.md" /* webpackChunkName: "component---src-pages-blog-repl-md" */),
  "component---src-pages-blog-starting-an-open-source-md": () => import("/opt/build/repo/src/pages/blog/starting-an-open-source.md" /* webpackChunkName: "component---src-pages-blog-starting-an-open-source-md" */),
  "component---src-pages-blog-svelte-md": () => import("/opt/build/repo/src/pages/blog/svelte.md" /* webpackChunkName: "component---src-pages-blog-svelte-md" */),
  "component---src-pages-blog-typescript-to-rust-md": () => import("/opt/build/repo/src/pages/blog/typescript-to-rust.md" /* webpackChunkName: "component---src-pages-blog-typescript-to-rust-md" */),
  "component---src-pages-blog-tic-tac-toe-md": () => import("/opt/build/repo/src/pages/blog/tic-tac-toe.md" /* webpackChunkName: "component---src-pages-blog-tic-tac-toe-md" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

